td {
  padding: 0px;
}

.main-container {
  @apply flex justify-center w-full bg-slate-100;
}

.mt-header-mb-footer {
  @apply mt-16 mb-24;
}

.header-table-row {
  @apply flex text-sm text-white gap-1 select-none;
}

.header-talbe-data {
  @apply bg-blue-900 font-bold py-2 shadow-lg rounded-sm;
}

.detail-table-row {
  @apply flex text-sm text-slate-700 gap-1 mt-1 select-none;
}

.detail-table-data {
  @apply flex justify-center items-center h-10 px-1.5 py-2.5 shadow-lg rounded-sm duration-500;
}

.form-bg-layout-base {
  @apply fixed inset-0 flex justify-center bg-black bg-opacity-50 items-center z-10;
}

.form-input-base {
  @apply w-full px-3 py-2 h-10 border rounded-sm text-base;
}

.weekday-recipe-data-base {
  @apply px-4 py-2 min-h-10 w-48 rounded-sm shadow-md select-none;
}

.login-input-base {
  @apply text-base w-full px-4 py-2 mt-1 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500;
}

.login-link-text-base {
  @apply text-sm text-blue-500 font-bold hover:underline hover:cursor-pointer hover:text-red-700;
}

.button-base {
  @apply shadow-md border-b-4 hover:shadow-lg active:shadow-sm active:border-b-transparent active:translate-y-0.5 transition duration-100
}

.white-button-base {
  @apply bg-white border-gray-300 shadow-md border-x border-t border-b-4 hover:shadow-lg active:shadow-sm active:border-b active:translate-y-0.5 transition duration-100
}
